import styled from 'styled-components';
const BannerArea = styled.section`
  background: linear-gradient(#380036, #0CBABA);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 260px;
  padding-bottom: 235px;
  position: relative;
  @media (max-width: 1600px) {
    padding-top: 210px;
    padding-bottom: 170px;
  }
  @media (max-width: 1199px) {
    padding-top: 140px;
    padding-bottom: 140px;
  }
  @media (max-width: 575px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .Container {
    display: flex;
  }
  h2 {
    font-weight: 500;
    font-size: 62px;
    line-height: 1.21;
    letter-spacing: -2px;
    color: #ffffff;
    margin-bottom: 20px;
    @media (max-width: 1600px) {
      font-size: 40px;
      max-width: 414px;
    }
    @media (max-width: 574px) {
      font-size: 30px;
      max-width: 91%;
      line-height: 40px;
    }
  }
  p {
    font-weight: normal;
    font-size: 18px;
    line-height: 2.11;
    letter-spacing: -0.3px;
    color: #ffffff;
    margin-bottom: 0;
    max-width: 515px;
    opacity: 0.8;
    @media (max-width: 1600px) {
      font-size: 16px;
      max-width: 466px;
    }
  }
  .bannerMoc {
    position: absolute;
    bottom: 145px;
    right: 70px;
    max-width: 750px;
    @media (max-width: 1600px) {
      max-width: 750px;
      right: 43px;
    }
    @media (max-width: 1199px) {
      max-width: 500px;
    }
    @media (max-width: 768px) {
      max-width: 350px;
      right: 20px;
      bottom: 170px;
    }
    @media (max-width: 575px) {
      display: none;
    }
  }
`;
export const Col = styled.div`
  max-width: 70%;
  margin: 0 1em 0 0;
  @media (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

export default BannerArea;
